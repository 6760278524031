<template>
	<div class="card-service br-4 bg-white p-3 cr-pointer">
		<div class="d-middle justify-content-between">
			<p class="f-18 fr-medium item-nombre pr-2"> {{ item.nombre }} </p>
			<div
				:class="`${item.estado == 1 ? 'state-active' : 'state-inactive'} rounded-pill wm-126px wM-132px h-22px f-14 d-middle-center px-2 mb-auto`">
				Servicio {{ item.estado == 1 ? 'activo' : 'inactivo' }}
			</div>
		</div>
		<p class="f-16 fr-light lh-19 mt-2 description h-58px"> {{ item.descripcion }} </p>
		<el-divider />
		<div class="d-middle h-38px" style="gap: 0.75rem">
			<div v-for="(data, index) in item.cedis.slice(0, 2)" :key="index" class="d-middle br-8 border p-1 w-113px">
				<img :src="data.img" alt="" class="wh-28 bg-dark mr-2 br-5">
				<p class="tres-puntos f-15">{{ data.nombre }}</p>
			</div>
			<div v-if="item.cedis_count > 2" class="wh-38 bg-dark text-white rounded-circle d-middle-center">
				+{{ item.cedis_count - 2 }}
			</div>
		</div>
		<div class="d-middle mt-3">
			<i class="icon-pedidos" />
			<p class="f-16 ml-2">{{ item.cantPedidos }} Pedidos activos</p>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		item: {
			type: Object,
			default: () => { }
		},
	},
	data() {
		return {
			firstTwo: []
		}
	},
	mounted() {
		this.setFirstTwoCedits();
	},
	methods: {
		setFirstTwoCedits() {
			if (this.item.cedis.length === 1) {
				this.firstTwo = this.item.cedis.slice(0, 1)
			} else {
				this.firstTwo = this.item.cedis.slice(0, 2)
			}
		},
		goToDetails(id) {
			console.log("🚀 ~ file: index.vue:138 ~ goToDetails ~ id:", id)
			this.$router.push({ name: 'servicios.paqueteria.detalles', params: { id } })
		}
	}
}
</script>

<style lang="scss" scoped>
.card-service {
	color: #000000;
	width: 325px;
	height: 268px;
	box-shadow: 0px 3px 4px #00000014;
	.item-nombre{
		display: -webkit-box;
		overflow: hidden;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		line-height: 1.2; /* Adjust line height as needed */
		max-height: 45px; /* Adjust max height based on line height */
		min-height: 45px; /* Adjust max height based on line height */
		text-overflow: ellipsis;
	}
	.description {
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
	.state{
		&-active{
			background-color: #29D88430;
			color: #29D884;
		}
		&-inactive{
			background-color: #DFE4E8;
			color: #637381;
		}
	}
}

// .sec-servicios-detalles{
// 	.state{
// 		&-active{
// 			background-color: #29D88430;
// 			color: #29D884;
// 		}
// 		&-inactive{
// 			background-color: #DFE4E8;
// 			color: #637381;
// 		}
// 	}
// }
</style>